import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/lang'

Vue.use(VueI18n)


const numberFormats = {
  'de-DE': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  },
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'ja-JP': {
    currency: {
      style: 'currency', currency: 'JPY', currencyDisplay: 'symbol'
    }
  }
}

export default new VueI18n({
  numberFormats,
  locale: 'de',
  fallbackLocale: 'de',
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages, // set locale messages
});
