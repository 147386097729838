import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import store from '@/store';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
    theme: { 
        dark: store.getters.getTheme,
        themes: {
            light: {
                primary: "#04bbee",
                error: "#cc0047",
                secondary: "#40ffff",
                accent: '#142933',
                success: colors.green,
            },
            dark: {
                primary: "#04bbee",
                error: "#cc0047",
                secondary: "#40ffff",
                accent: '#142933',
                success: colors.green,
            }
        }
    },
}

export default new Vuetify(opts)