import Vue from "vue";
import store from "@/store";

const PermissionManager = {
  install() {
    Vue.prototype.$hasRole = (neededRole) => {

		const ROLES = {
			'administrator': 0,
			'system': 0,
			'developer': 0,
			'agent': 1,
			'partner': 2,
			'organisation_manager': 3,
			'location_manager': 4,
			'employee': 5
		}

		const userRole = ROLES[store.getters.getUser.role];
		neededRole = ROLES[neededRole] || 5;
		
		if(userRole <= neededRole) return true;
      	return false;
    };
  },
};

export default PermissionManager;
