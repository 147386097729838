<template>
  <v-btn
    class="topnav-btn"
    x-small
    dense
    depressed
    :to="item.url"
    v-if="!item.menu"
  >
    <v-icon x-small> {{ item.icon }} </v-icon>&nbsp;
    <div v-if="!smallScreen">{{ $t(item.title) }}</div>
  </v-btn>
  <v-menu offset-y dark left v-else bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="`topnav-btn${activeClass}`"
        x-small
        dense
        v-bind="attrs"
        v-on="on"
        depressed
      >
        <v-icon x-small>{{ item.icon }}</v-icon>
        &nbsp;
        <div v-if="!smallScreen">{{ $t(item.title) }}</div>
        <v-icon x-small keepInMeowMeow>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <template v-for="n in item.menuitems">
        <v-list-item :key="n" v-if="!n.submenu" :to="n.url">
          <v-list-item-title>
            <v-icon x-small> {{ n.icon }} </v-icon>&nbsp;
            {{ $t(n.title) }}
          </v-list-item-title>
        </v-list-item>

        <v-menu open-on-hover :key="n" offset-x v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" class="menu_hover">
              <v-list-item-title>
                <v-icon x-small> {{ n.icon }} </v-icon>&nbsp;
                {{ $t(n.title) }}&nbsp;
                <v-icon x-small style="">mdi-chevron-double-right</v-icon>&nbsp;
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list dense>
            <v-list-item v-for="m in n.submenuitems" :key="m" :to="m.url" link>
              <v-list-item-title>
                <v-icon x-small> {{ m.icon }} </v-icon>&nbsp;
                {{ $t(m.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      activeClass: "",
    };
  },
  methods: {
    getIsActive(){
      this.activeClass = (this.item.sourcePath != null && window.location.pathname.startsWith(this.item.sourcePath) ? ' menu_active' : '')
    }
  },
  watch: {
    $route() {
      this.getIsActive();
    },
  },
};
</script>

<style>
.topnav-btn {
  border-radius: 0 !important;
}
.topnav-btn.menu_active {
  background-color: #3c3c3c !important;
  color: #fff !important;
}
.v-list-item {
  padding: 0 8px;
}
.v-list {
  padding: 2px 0 !important;
}
.menu_hover[aria-expanded="true"]::before {
  opacity: 0.08;
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
