<template>
  <div>
    <v-app-bar dark flat dense class="topnav header-bar">
      <v-img
        style="cursor: pointer"
        to="/"
        :src="'https://cdn.lan1.eu/logos/lan1_logo_circle_white.png'"
        max-width="23"
      ></v-img>
      &nbsp;
      &nbsp;
      <span class="btn-wrap" v-for="item in items" :key="item">
        <MenuBarButton :item="item"></MenuBarButton>
      </span>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            style="cursor: pointer"
            @click="goto('/manage/user/' + $store.getters.getUser.id)"
            v-bind="attrs"
            v-on="on"
            >mdi-account-circle-outline</v-icon
          >
        </template>
        <span>
          {{ $t("name") + ": " + username }}<br />
          {{ $t("role") + ": " + $t($store.getters.getUserRole) }}
        </span>
      </v-tooltip>
      &nbsp;
      &nbsp;
      <!-- <v-btn
        depressed
        dense
        x-small
        icon
        @click.prevent="switchTheme"
        :title="$t('switchTheme')"
        ><v-icon x-small>{{
          theme ? "mdi-lightbulb-outline" : "mdi-lightbulb-on"
        }}</v-icon></v-btn
      > -->
      <!-- <v-menu dark offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            icon
            v-bind="attrs"
            v-on="on"
            :title="$t('switchLang')"
          >
            <v-icon x-small>mdi-earth</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            style="cursor: pointer"
            v-for="lang in language"
            :key="lang"
          >
            <v-list-item-title @click.prevent="changeLang(lang.key)">{{
              lang.text
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <MenuBarButton :item="{title: 'settings', icon: 'mdi-cogs', url: '/settings'}" />
      <MenuBarButton :item="{title: 'logout', icon: 'mdi-logout', url: '/logout'}" />

      <!-- <v-menu offset-y dark left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="topnav-btn" x-small v-bind="attrs" v-on="on" depressed>
            <v-icon x-small>mdi-cogs</v-icon>&nbsp;

            <div v-if="!smallScreen">{{ $t("settings") }}</div>
            <v-icon x-small keepInMeowMeow>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/settings">
            <v-list-item-title>
              <v-icon x-small> mdi-cogs </v-icon>&nbsp;
              {{ $t("Settings") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/logout">
            <v-list-item-title>
              <v-icon x-small> mdi-logout </v-icon>&nbsp;
              {{ $t("logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
    <br>
    <br>
    
  </div>
</template>

<script>
import MenuBarButton from "@/components/MenuBarButton.vue"
export default {
  components: {
    MenuBarButton
  },
  data() {
    return {
      drawer: false,
      isMobile: false,
      smallScreen: false,
      sizes: ["x-small", "small", "default", "large", "x-large"],
      items: [
        {
          title: "Dashboard",
          icon: "mdi-monitor-dashboard",
          url: "/dashboard",
        },
        {
          title: "SupportDashboard",
          icon: "mdi-wrench",
          url: "/support",
        },
        {
          title: "Wlan",
          icon: "mdi-wifi",
          sourcePath: "/wlan",
          menu: true,
          menuitems: [
            {
              title: "Hotspots",
              icon: "mdi-sitemap",
              url: "/wlan/hotspots",
            },
            {
              title: "Voucher",
              icon: "mdi-ticket-percent",
              url: "/wlan/voucher",
            },
            {
              title: "Pricings",
              icon: "mdi-cash-multiple",
              url: "/wlan/pricings",
            },
          ],
        },
        {
          title: "Terminals",
          icon: "mdi-server-network",
          sourcePath: "/terminal",
          menu: true,
          menuitems: [
            {
              title: "Terminals",
              icon: "mdi-server-network",
              url: "/terminal/terminal",
            },
            {
              title: "Invoice",
              icon: "mdi-cash",
              url: "/terminal/invoice",
            },
          ],
        },
        {
          title: "Shops",
          icon: "mdi-store",
          sourcePath: "/shop",
          menu: true,
          menuitems: [
            {
              title: "Shops",
              icon: "mdi-store",
              url: "/shop/shops",
            },
            {
              title: "Purchases",
              icon: "mdi-cart",
              url: "/shop/purchases",
            }
          ]
        },
        {
          title: "Emobility",
          icon: "mdi-ev-station",
          sourcePath: "/emobility",
          menu: true,
          menuitems: [
            {
              title: "ChargingStations",
              icon: "mdi-ev-station",
              url: "/emobility/stations",
            },
            {
              title: "ChargingCards",
              icon: "mdi-card-bulleted-outline",
              url: "/emobility/cards",
            },
            {
              title: "ChargingTransactions",
              icon: "mdi-lightning-bolt",
              url: "/emobility/transactions",
            },
            {
              title: "ChargingReceipts",
              icon: "mdi-receipt",
              url: "/emobility/receipts",
            },
            {
              title: "Pricings",
              icon: "mdi-cash-multiple",
              url: "/emobility/pricings",
            },
          ],
        },
        {
          title: "Management",
          icon: "mdi-webpack",
          sourcePath: "/manage",
          menu: true,
          menuitems: [
            {
              title: "Users",
              icon: "mdi-account-group",
              url: "/manage/users",
            },
            {
              title: "Organisations",
              icon: "mdi-office-building",
              url: "/manage/organisation",
            },
            {
              title: "Locations",
              icon: "mdi-map-marker",
              url: "/manage/locations",
            },
            {
              title: "Landingpages",
              icon: "mdi-application-outline",
              url: "/manage/landingpages",
            },
            {
              title: "Templates",
              icon: "mdi-notebook-edit",
              url: "/manage/templates",
            },
          ],
        },
        {
          title: "Util",
          icon: "mdi-cog",
          menu: true,
          sourcePath: "/util",
          menuitems: [
            {
              title: "Custom Style",
              icon: "mdi-format-color-fill",
              url: "/util/style",
            },
          ],
        },
        {
          title: "Dev",
          icon: "mdi-language-html5",
          menu: true,
          sourcePath: "/dev",
          menuitems: [
            {
              title: "Dev",
              icon: "mdi-console-line",
              submenu: true,
              submenuitems: [
                {
                  title: "Dev",
                  icon: "mdi-console-line",
                  url: "/dev/test",
                },
                {
                  title: "Dev2",
                  icon: "mdi-console-line",
                  url: "/dev/test2",
                },
              ],
            },
            {
              title: "Dev2",
              icon: "mdi-webpack",
              url: "/dev/test2",
            },
          ],
        },
      ],
      theme: true,
      currentLanguage: "de",
      lang: "de",
      language: [
        { key: "de", value: "de", flag: "de", text: "Deutsch" },
        { key: "en", value: "en", flag: "gb", text: "English" },
      ],
      currSize: "x-small",
    };
  },
  created() {},
  methods: {
    switchTheme() {
      this.theme = !this.theme;
      this.$store.dispatch("setDarkMode", this.theme);
      this.$vuetify.theme.dark = this.theme;
    },
  },
  computed: {
    username() {
      if (!this.$store.getters.getUser.contact.firstname) return this.$store.getters.getUser.name;
      return `${this.$store.getters.getUser.contact.firstname} ${this.$store.getters.getUser.contact.lastname}`;
    },
  },
};
</script>

<style lang="scss">
.topnav {
  height: auto !important;
  padding: 0%;
  margin-bottom: 1rem;
}

.topnav-btn {
  height: 28px !important;
}

.v-toolbar__content {
  padding: 0px;
  height: auto !important;
}
.route-header {
  position: absolute;
  padding: 7px;
  margin-top: 16px;
  font-size: 1.3rem;
}
.header-keepontop {
  position: fixed;
  z-index: 9999;
}
.header-bar {
  position: fixed !important;
  top: 0 !important;
  z-index: 9999;
}
.topnav-btn {
  border-radius: 0 !important;
}
</style>