<template>
  <v-app id="app">
    <MenuBar v-if="$store.getters.isLoggedIn" />
    <router-view></router-view>
  </v-app>
</template>

<script>
import MenuBar from "@/components/MenuBar.vue";
import AuthService from "@/services/AuthService.js";
import SecretService from "@/services/SecretService.js";
export default {
  components: {
    MenuBar,
  },
  data() {
    return {
      theme: false,
    };
  },
  async created() {
    SecretService.consoleCat();
    this.theme = this.$store.getters.getIsDarkMode;
    localStorage["Language"] == null
      ? (localStorage["Language"] = this.$i18n.locale)
      : (this.$i18n.locale = localStorage["Language"]);
    // if (this.$store.getters.getIsDarkMode == null) {
    //   this.$store.dispatch( "setDarkMode",window.matchMedia("(prefers-color-scheme:dark)").matches);
    // }
    // this.$vuetify.theme.dark = this.$store.getters.getIsDarkMode;
		await this.checkLogin();
		setInterval(this.checkLogin, 60000);
  },
  methods: {
    async checkLogin() {
      if(this.$route.meta.noAuth == true) return
      let resp = await AuthService.isLoggedIn();
      if(resp == undefined || resp.status != 200){
          if(this.$store.getters.isLoggedIn){
            resp = await AuthService.refresh();
            if(resp.success){
              this.$store.commit("login", resp.data);
              return;
            }
          }
          this.logout();
          return;
      }

      if(resp.data.is_customer){
        this.logout()
        this.$swal({
          title: this.$t("Error"),
          text: this.$t("You are not allowed to access this page"),
          icon: "error",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false
        });
      }

    },
    logout(){
      this.$store.dispatch("logout");
      this.$router.push(
        "/login?redirect=" + location.pathname
      );
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/global.scss";
</style>