import Vue from "vue";
import config from '@/config';
import store from '@/store';
import i18n from '@/i18n';

const Helper = {
	install() {
		// Gibt falls zurück, falls die App nicht im Entwicklungsmodus läuft oder der Parameter "noDev" in der URL enthalten ist
		Vue.prototype.$isDev = () => {

			if(window.location.search.includes("noDev")) return false;
			
			return config.IS_DEVELOPMENT || !config.IS_PROD || false;

		},
		// Gibt zurück, ob die App im Produktivmodus läuft
		Vue.prototype.$isProd = () => {
			return config.IS_PROD || !config.IS_DEVELOPMENT || false;
		},
		// Formatiert ein Object in ein Array mit den Keys als ID und den Values als Name
		Vue.prototype.$objToArr = (obj) => {
			const arr = [];
			
			Object.keys(obj).forEach(key => {
				arr.push({id: (key*1), name: obj[key]});
			});
			return arr;
		},
		// Gibt zurück, ob der aktuelle Benutzer die Berechtigung hat, das übergebenen Modul zu sehen
		Vue.prototype.$hasModule = (module) => {

			if(store.getters.getModules.length == 0) return true;
			
			return store.getters.getModules.includes(module);
		},
		// Gibt zurück, ob der aktuelle Benutzer die Berechtigung hat, die übergebenen Module zu sehen
		Vue.prototype.$hasModules = (modules) => {

			if(store.getters.getModules.length == 0) return true;

			return modules.every(module => store.getters.getModules.includes(module));
		},
		// Formatiert eine Zeitangabe in eine Zeitangabe mit Datum und Uhrzeit (z.B. 01.01.2020 12:00:00)
		Vue.prototype.$formatDate = (indate) => {
			const date = new Date(indate);

			const format = new Intl.DateTimeFormat(i18n.locale, {
				timeZone: (store.getters.getUser.timezone || "Europe/Berlin"),
				year: 'numeric', month: '2-digit', day: '2-digit',
				hour: 'numeric', minute: 'numeric', second: 'numeric'
			})
			.format(date);
			return format;
		},
		// Formatiert eine Zeitangabe in eine relative Zeitangabe (vor 5 Minuten, vor 2 Stunden, vor 3 Tagen)
		Vue.prototype.$formatRelativeTime = (indate) => {
			const date = new Date(indate);
			const today = new Date();

			const secondsBetween = Math.floor((today - date) / 1000);
			const minutesBetween = Math.floor((today - date) / (1000 * 60));
			const hoursBetween = Math.floor((today - date) / (1000 * 60 * 60));
			const daysBetween = Math.floor((today - date) / (1000 * 60 * 60 * 24));
			const monthsBetween = Math.floor((today - date) / (1000 * 60 * 60 * 24 * 30));
			const yearsBetween = Math.floor((today - date) / (1000 * 60 * 60 * 24 * 365));

			if(secondsBetween < 60) return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-secondsBetween, 'seconds') + "").toFirstUpper();
			if(minutesBetween < 60) return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-minutesBetween, 'minutes') + "").toFirstUpper();
			if(hoursBetween < 24) return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-hoursBetween, 'hours') + "").toFirstUpper();
			if(daysBetween < 90) return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-daysBetween, 'days') + "").toFirstUpper();
			if(monthsBetween < 12) return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-monthsBetween, 'months') + "").toFirstUpper();
			return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-yearsBetween, 'years') + "").toFirstUpper();



			// return (new Intl.RelativeTimeFormat(i18n.locale, {numeric: 'auto'}).format(-daysBetween, 'days') + "").toFirstUpper();
		},
		// Formatiert eine Zahl in die zur Sprache passende Schriftweise für Euro
		Vue.prototype.$formatMoney = (amount) => {
			return new Intl.NumberFormat(i18n.locale, {
				style: 'currency',
				currency: "EUR",
				minimumFractionDigits: 2
			}).format(amount);
		},
		// Gibt eine zufällige Zahl zwischen min und max zurück
		Vue.prototype.$random = (min, max) => {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		},
		Vue.prototype.$randomDates = () => {
			const first_date = new Date(Math.floor(Math.random() * (new Date().getTime() - 100000000 + 1)) + 100000000);
			const second_date = new Date(Math.floor(Math.random() * (new Date().getTime() - first_date.getTime() + 1)) + first_date.getTime());
			return [first_date, second_date];
		},
		Vue.prototype.$isCustomer = () => {
			return store.getters.getUser.is_customer;
		},

		// Setzt den ersten Buchstaben eines Strings in Großbuchstaben
		String.prototype.toFirstUpper = function() {
			return this.charAt(0).toUpperCase() + this.slice(1);
		}
	}


}

export default Helper;