import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import config from "@/config";

const Login = () => import("@/views/Login.vue");
const Logout = () => import("@/views/Logout.vue");
const Settings = () => import("@/views/Settings.vue");
const Password = () => import("@/views/Password.vue");

const ManageUsers = () => import("@/views/manage/Users.vue")
const ManageOrganisations = () => import("@/views/manage/Organisations.vue")
const ManageLocations = () => import("@/views/manage/Locations.vue")

const ShopPurchases = () => import("@/views/shops/Purchases.vue")
const ShopShops = () => import("@/views/shops/Shops.vue")

const EmobilityChargingStations = () => import("@/views/emobility/ChargingStations.vue")
const EmobilityChargingCards = () => import("@/views/emobility/ChargingCards.vue")
const EmobilityChargingTransactions = () => import("@/views/emobility/ChargingTransactions.vue")
const EmobilityChargingReceipts = () => import("@/views/emobility/ChargingReceipts.vue")
const EmobilityPricings = () => import("@/views/emobility/ChargingPricings.vue")
const EmobilityReceipt = () => import("@/views/emobility/subviews/ChargingReceipt.vue")


const UtilCustomStyle = () => import("@/views/util/CustomStyle.vue")

const ManageUser = () => import("@/views/manage/subviews/User.vue")

const NotFound = () => import("@/views/segments/404.vue");
Vue.use(Router);

let router =  new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      meta: {noAuth: true},
      component: Login
    },
    {
      path: "/logout",
      name: "Logout",
      meta: {noAuth: true},
      component: Logout
    },
    {
      path: "/password",
      name: "Password",
      meta: {noAuth: true},
      component: Password
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
    },

    {
      path: "/manage/users",
      name: "Manage Users",
      component: ManageUsers
    },
    {
      path: "/manage/organisation",
      name: "Manage Organisations",
      component: ManageOrganisations
    },
    {
      path: "/manage/locations",
      name: "Manage Locations",
      component: ManageLocations
    },
    {
      path: "/manage/user/:id",
      name: "Manage User",
      component: ManageUser
    },

    {
      path: "/shop/purchases",
      name: "Shop Purchases",
      component: ShopPurchases
    },
    {
      path: "/shop/shops",
      name: "Shop Shops",
      component: ShopShops
    },

    {
      path: "/emobility/stations",
      name: "Emobility Charging Stations",
      component: EmobilityChargingStations
    },
    {
      path: "/emobility/cards",
      name: "Emobility Charging Cards",
      component: EmobilityChargingCards
    },
    {
      path: "/emobility/transactions",
      name: "Emobility Charging Transactions",
      component: EmobilityChargingTransactions
    },
    {
      path: "/emobility/receipts",
      name: "Emobility Charging Receipts",
      component: EmobilityChargingReceipts
    },
    {
      path: "/emobility/pricings",
      name: "Emobility Pricings",
      component: EmobilityPricings
    },
    {
      path: "/emobility/receipt/:id",
      name: "Emobility Receipt",
      component: EmobilityReceipt
    },

    {
      path: "/util/style",
      name: "Util Custom Style",
      component: UtilCustomStyle
    },


    {
      path: "*",
      name: "Page not Found",
      meta: {
        icon: "mdi-monitor-off",
        permission: "employee"
      },
      component: NotFound
    }
  ]
});

const dev_routes = [
];

router.onError(error => {
  console.log(error);
  console.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

// router on change
router.beforeEach((to,from, next) => {
  let split = to.path.split("/");
  let module = split[1].toLowerCase();
  const modules = ["hospitality", "housing"];

  if (modules.includes(module)) {
    store.dispatch("changeModule", module);
  }
  next();
})


if(config.IS_DEVELOPMENT){
  router.addRoutes(dev_routes)
}


export default router