import axios from 'axios';
import config from '@/config';

const url = `${config.API_URL || "localhost"}/`;
//const url = '/auth/';


const ident = "https://identity."


export default {
  login(credentials) {
    return this.doPost(ident, 'auth/login', credentials);
  },
  logout(credentials) {
    return this.doPost(ident, 'auth/logout', credentials);
  },
  refresh(credentials) {
    return this.doPost(ident, 'auth/refresh', credentials);
  },
  isLoggedIn(credentials) {
    return this.doPost(ident, 'auth/info', credentials);
  },
  reset(credentials) {
    return this.doPost(ident, 'auth/reset', credentials);
  },
  doGet(service, target){
    return axios
    .get(service +  url + target)
    .then(response => response)
    .catch(e => e.response);
  },
  doPost(service, target, credentials){
    return axios
    .post(service +  url + target, credentials)
    .then(response => response)
    .catch(e => e.response);
  },
  doPut(service, target, credentials) {
    return axios.put(service +  url + target, credentials)
    .then(response => response)
    .catch(e => e.response);
  },
  doDelete(service, target) {
    return axios.delete(service +  url + target)
    .then(response => response.status == 204)
    .catch(e => e.response);
  },
  doGetDownload(service, target) {
    return axios
    .get(service +  url + target, { responseType: 'arraybuffer' })
    .then(response => response)
    .catch(e => e.response);
  },
  doPostDownload(service, target, credentials) {
    return axios
    .post(service +  url + target, credentials, { responseType: 'arraybuffer' })
    .then(response => response)
    .catch(e => e.response);
  },
  download(response, filename) {
    var newBlob = new Blob([response.data], { type: 'application/pdf' })

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob)
      return
    }

    const data = window.URL.createObjectURL(newBlob)
    var link = document.createElement('a')
    link.href = data
    link.download = filename + '.pdf'
    link.click()
    setTimeout(function () {
      window.URL.revokeObjectURL(data)
    }, 100)
  },
};
