import Vue from 'vue'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts'

import Helper from '@/plugins/Helper';
import ThemeHelper from '@/plugins/ThemeHelper';
import Permissions from '@/plugins/Permissions';

// import "@/components/global/_global";

Vue.config.productionTip = false

Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;


const optionsSwal = {
    confirmButtonColor: 'rgb(115,141,40)',
    cancelButtonColor: 'rgba(0, 0, 0, 0.6);',
};

Vue.use(Helper)
Vue.use(ThemeHelper)
Vue.use(Permissions)
Vue.use(VueSweetalert2, optionsSwal)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


new Vue({
  Axios,
  VueAxios,
  vuetify,
  optionsSwal,
  VueSweetalert2,
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')