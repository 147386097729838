import Vue from "vue";
import { getCurrentInstance } from 'vue'
// import store from '@/store';
// import vuetify from "vuetify";

const ThemeHelper = {
	install() {
		// Gibt zurück, ob die App im Produktivmodus läuft
		Vue.prototype.$changeTheme = () => {

			// // themes.push(store.getters.ownCustomThemes)
			// let ownCustomThemes = store.getters.ownCustomThemes;
			// if(ownCustomThemes) {
			// 	ownCustomThemes.forEach((ownTheme) => {
			// 		themes.push(ownTheme);
			// 	});
			// }
			
			// let customTheme = themes.find(x => x.name == theme);
			// if(!customTheme) {
			// 	customTheme = themes.find(x => x.name == 'default');
			// }

			// store.dispatch('setCustomTheme', customTheme);

			// Object.entries(customTheme).forEach(([key, value]) => {
			// 	document.documentElement.style.setProperty("--" + key, value);
			// });
			// vuetify.theme.themes.dark.error = "green";

			// getCurrentInstance().$vuetify.theme.themes.dark.error = 'green';
			let app = getCurrentInstance();
			console.log(app)
			app.proxy.$vuetify.theme.themes.dark.error = '#acacac';
		}
	}
}

export default ThemeHelper;