import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
const getDefaultState = () => {
  return {
    token: '',
    user: {},
    menuCollabsed: true,
    theme: 'system', // system, light, dark
    customstyle: null,
    activeLocation: -1,
    current_module: null,
    customTheme: null,
    filter: {},
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token != '' ? true : false;
    },
    getUser: state => {
      return state.user;
    },
    getIsDarkMode: state => {
      return state.darkmode;
    },
    getThemeDesign: state => {
      return state.theme
    },
    getTheme: state => {
      // for legacy reasons
      if(state.theme === true) {
        state.theme = 'dark';
        return true;
      }
      if(state.theme === false) {
        state.theme = 'light';
        return false;
      }

      if(state.theme === 'dark') return true;
      if(state.theme === 'light') return false;

      if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) return true;
      return false;


      // return state.theme;
    },
    getUserRole: state => {
      return state.user.got_roles[0];
    },
    getCustomStyle: state => {
      return state.customstyle;
    },
    getCustomTheme: state => {
      return state.customTheme;
    },
    getFilter: state => {
      return state.filter;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_MENU_COLLABSED(state, menuCollabsed) {
      state.menuCollabsed = menuCollabsed
    },
    RESET: state => {
      state.token = '';
      state.user = {};
    },
    SET_DARKMODE: (state, darkmode) => {
      state.darkmode = darkmode;
    },
    SET_CUSTOM_STYLE: (state, customstyle) => {
      state.customstyle = customstyle ? JSON.parse(customstyle) : null;
    },
    SET_CUSTOM_THEME: (state, customTheme) => {
      state.customTheme = customTheme;
    },
    SET_FILTER: (state, filter) => {
      state.filter[filter.key] = filter.value;
    },
    SET_THEME: (state, theme) => {
      state.theme = theme;
    },
  },
  actions: {
    login: ({ commit }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    },
    setCustomStyle: ({ commit }, customstyle) => {
      commit('SET_CUSTOM_STYLE', customstyle);
    },
    setDarkMode({ commit }, darkmode) {
      commit('SET_DARKMODE', darkmode);
    },
    switchDarkMode: ({commit, state }) => {
      commit("SET_DARKMODE", !state.darkmode);
    },
    setActiveLocation: ({commit }, activeLocation) => {
      commit("SET_ACTIVE_LOCATION", activeLocation);
    },
    clearActiveLocation: ({commit }) => {
      commit("SET_ACTIVE_LOCATION", -1);
    },
    updateUser: ({commit}, user) => {
      commit('SET_USER', user);
    },
    setCustomTheme: ({ commit }, customTheme) => {
      commit('SET_CUSTOM_THEME', customTheme);
    },
    setFilter: ({ commit }, filter) => {
      commit('SET_FILTER', filter);
    },
    setTheme: ({commit}, theme) => {
      commit("SET_THEME", theme);
    },
  }
});